import React from 'react'
import { Container, Heading, Box } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'

import Seo from '../../../components/seo'
import ProductListing from '../../../components/product-listing'

const CollectionIndex = ({
  data: { collection },
  pageContext: { handle, breadcrumb },
}) => {
  const formattedHandle = handle.replace('-', ' ')

  const descriptionsArray = collection.descriptionHtml.split('<!--split-->')
  // const bottomDescription = collection.descriptionHtml.split('<!--split-->')[1]
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title={
          collection && collection.seo
            ? collection.seo.title
            : `${formattedHandle} | Mud Foods`
        }
        description={collection && collection.seo && collection.seo.description}
      />

      <Container py={5}>
        <Heading as="h1" textStyle="h1">
          {collection && collection.title ? collection.title : formattedHandle}
        </Heading>
        {descriptionsArray.length > 1 && (
          <Box
            dangerouslySetInnerHTML={{
              __html: descriptionsArray[0],
            }}
          />
        )}

        <ProductListing products={collection.products}></ProductListing>

        {descriptionsArray.length > 1 ? (
          <Box
            dangerouslySetInnerHTML={{
              __html: descriptionsArray[1],
            }}
          />
        ) : (
          collection.descriptionHtml && (
            <Box
              dangerouslySetInnerHTML={{
                __html: collection.descriptionHtml,
              }}
            />
          )
        )}
      </Container>
    </Layout>
  )
}

export default CollectionIndex

export const query = graphql`
  query ($handle: String!) {
    collection: shopifyCollection(handle: { eq: $handle }) {
      handle
      seo {
        description
        title
      }
      title
      descriptionHtml
      products {
        ...ProductCard
      }
      image {
        originalSrc
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              formats: [AUTO, WEBP]
              quality: 40
              width: 400
            )
          }
        }
      }
    }
  }
`
